// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import chat from "@src/views/apps/chat/store/reducer";
import todo from "@src/views/apps/todo/store/reducer";
import users from "@src/views/apps/user/store/reducer";
import email from "@src/views/apps/email/store/reducer";
import invoice from "@src/views/apps/invoice/store/reducer";
import calendar from "@src/views/apps/calendar/store/reducer";
import ecommerce from "@src/views/apps/ecommerce/store/reducer";
import dataTables from "@src/views/tables/data-tables/store/reducer";

// common reducers
import commonreducer from "@src/views/store/reducer";

//Dashboard Reducer
import dashboardreducer from "@src/views/dashboard/FinanceDashboard/store/reducer";
import acaddashboardreducer from "@src/views/dashboard/AcademicDashboard/store/reducer";
import branchListreducer from "@src/views/dashboard/BranchList/store/reducer";
import reaReducer from "@src/views/dashboard/ResultAnalysisDashboard/store/reducer";
import studentDashboard from "@src/views/dashboard/StudentDashboard/store/reducer";
import StuDashboard from "@src/views/dashboard/StuDashboard/store/reducer";
import crmDashboard from "@src/views/dashboard/CrmDashboard/store/reducer";
import npDashboard from "@src/views/dashboard/NaipunyaDashboard/store/reducer";
import VehicleReminders from "../../views/dashboard/TransportDashboard/store/reducer";
import EnquiriesReports from "../../views/dashboard/EnqDashboard/store/reducer";
import SmsDashboard from "../../views/dashboard/SmsDashboard/store/reducer";
import InventoryDashboard from "../../views/dashboard/InventoryDashboard/store/reducer";
import adbDashboard from "../../views/dashboard/adminDashboard/store/reducer";
import AttDashboardReducer from "../../views/dashboard/AttendanceDashboard/store/reducer/index.js";
import VASDetailsReducer from "../../views/dashboard/IdCardandVAS/store/reducer/index.js";
import UtilityList from "../../views/dashboard/FinanceDashboard/UtilityDetails/store/reducer";

// academic
// results
// progress card by exam
import progressexams from "@src/views/academics/results/progressbyexam/store/reducer";
// result entry
import resultentry from "@src/views/academics/results/resultentry/store/reducer";
import ResultViewByCls from "../../views/academics/results/resultViewByClass/store/reducer";
import TchrResAnalysisReducer from "../../views/academics/results/tchrResultAnalysis/store/reducer/index.js";
import MarksVerfyReducer from "../../views/academics/results/marksVerification/store/reducer/index.js";
//Token Entry
import TokenEntry from "../../views/crm/tokenEntry/store/reducer";
import TokenErp from "../../views/crm/tokenErp/store/reducer";
import smsReducer from "../../views/crm/smsTemplate/store/reducer";
import SmsTemplateTracker from "../../views/crm/smsTemplateTracker/store/reducer";
import MarketingSources from "../../views/crm/followUps/marketingSources/store/reducer";
import FeedBack from "../../views/crm/feedBack/store/reducer";
import FeedbackCycle from "../../views/crm/feedbackCycle/store/reducer";
import FeedbackQues from "../../views/crm/feedBackQuestions/store/reducer";
import GalleryEntry from "../../views/settings/gallery/store/reducer";
import ParentApp from "../../views/settings/parentApp/store/reducer";
import ModuelAccess from "../../views/settings/menu/moduleAccessManagement/store/reducer";
import WorkUpdate from "../../views/crm/workUpdate/store/reducer";
import StuPromoteDemote from "../../views/crm/studentsPromotions/store/reducer";
import StuExcelUpload from "../../views/crm/excelUpload/stuExcelUpload/store/reducer";
import StuIncomeExcelUpload from "../../views/crm/excelUpload/stuIncomeExcelUpload/store/reducer/index.js";
import IdExcelUploadReducer from "../../views/crm/excelUpload/idTemplateExcelUpload/store/reducer/index.js";
import TutorialReducer from "../../views/crm/tutorials/store/reducer";
import TutorialLinkReducer from "../../views/crm/tutorials/tutorialLinks/store/reducer";
import GetRefReducer from "../../views/crm/getReferences/store/reducer";
import RecExpBillDelReducer from "../../views/crm/recExpBillDeletion/store/reducer";

//Customers
import IMSReducer from "../../views/customers/addEdit/imssettings/store/reducer";
import CompanyAdd from "../../views/customers/addEdit/companyAdd/store/reducer";
import CustomerView from "../../views/customers/addEdit/customerView/store/reducer";
import CreditReducer from "../../views/customers/addEdit/creditLimits/store/reducer";
import CompanyList from "../../views/customers/reports/companyList/store/reducer";
import PriceCardReducer from "../../views/customers/priceCard/store/reducer/index.js";
import CompLockReducer from "../../views/customers/addEdit/companyLock/store/store/index.js";
import CompProfileReducer from "../../views/customers/profileSettings/store/reducer/index.js";

// academic settings
import naipunya from "@src/views/academics/naipunyaBatchAllocation/store/reducer";
import classes from "@src/views/academics/academicSettings/classes/store/reducer";
import mediums from "@src/views/academics/academicSettings/mediums/store/reducer";
import departments from "@src/views/academics/academicSettings/departments/store/reducer";
import degrees from "@src/views/academics/academicSettings/degree/store/reducer";
import sections from "@src/views/academics/academicSettings/sections/store/reducer";
import ProgressCards from "../../views/academics/academicSettings/progressCard/store/reducer";
import academicyear from "@src/views/academics/academicSettings/AcademicYear/store/reducer";
import Syllabus from "../../views/academics/academicSettings/syllabus/store/reducer";
import RegNoSeries from "@src/views/academics/academicSettings/registerNoSeries/store/reducer";
import classTeacherMappinG from "../../views/academics/academicSettings/classTeacherMapping/store/reducer";
import lessonPlanningDWSReducer from "@src/views/academics/lesson-planning/day-wise-schedule/store/reducer";
import tentativeExams from "@src/views/academics/lesson-planning/tentative-exams/store/reducer";
import SubjectSchedule from "@src/views/academics/lesson-planning/subject-wise-schedule/list/store/reducer";
import AnswerReducer from "../../views/academics/onlineExams/answers/store/reducer";
import SchOnlineClass from "@src/views/academics/lesson-planning/schedule-online-class/store/reducer";
import UpcOnlineClass from "../../views/academics/lesson-planning/upcoming-online-class/store/reducer";
import onlineClsAtt from "../../views/academics/lesson-planning/online-class-attendance/store/reducer";
import TimeTableReducer from "../../views/academics/timeTable/timeTableSetup/store/reducer";
import DayTimeTableReducer from "../../views/academics/timeTable/dayWiseTimeTable/store/reducer";
import TimeTableAdd from "../../views/academics/timeTable/timeTableAdd/store/reducer";
import CoScholasticReducer from "../../views/academics/results/co-scholastic/store/reducer";
import ElectiveSubjReducer from "../../views/academics/subjects/stuWiseElectiveSubject/store/reducer/index.js";
//SpeakWell
import Speakwell from "../../views/dashboard/StudentDashboard/Speakwell/store/reducer";
import swTopicsReducer from "../../views/academics/speakWellAssessment/topic/store/reducer";
import swMentorsReducer from "../../views/academics/speakWellAssessment/mentors/store/reducer";
import swReviewReducer from "../../views/academics/speakWellAssessment/speakWellReview/store/reducer";
import swCmpltdReducer from "../../views/academics/speakWellAssessment/swCmpltdVideos/store/reducer/index.js";
//Naipunya
import NaipunyaStuDB from "../../views/dashboard/StuDashboard/Naipunya/store/reducer";
import NaipunyaUndReviewReducer from "../../views/academics/naipunyaSettings/naipunyaReview/store/reducer/index.js";

// subjects
import subjects from "@src/views/academics/subjects/addsubjects/store/reducer";
import subjectmappings from "@src/views/academics/subjects/subjectMapping/store/reducer";
import subjectTopic from "@src/views/academics/subjects/subjectTopics/store/reducer";

// exam cell
import exams from "@src/views/academics/examcell/exam/store/reducer";
import halltickets from "@src/views/academics/examcell/halltickets/store/reducer";

// diary
import diaryadding from "@src/views/academics/diary/diaryAdding/store/reducer";
import AllDiaries from "../../views/academics/diary/allDiaries/store/reducer";
import PendDiaryReducer from "../../views/academics/diary/pendDiaries/store/reducer/index.js";

// cbse/ icse settings
import cbscpattern from "@src/views/academics/cbsc_icseSettings/cbscPattern/store/reducer";
import cbscgroups from "@src/views/academics/cbsc_icseSettings/cbscGroups/store/reducer";
import cbscSubjects from "@src/views/academics/cbsc_icseSettings/cbscSubjects/store/reducer";
import descriptive from "@src/views/academics/cbsc_icseSettings/descriptiveIndicator/store/reducer";
import CbsePattern from "../../views/academics/cbsc_icseSettings/cbscPattern/store/reducer";
//Communication
import SmsAllocation from "../../views/communication/allocations/smsAllocation/store/reducer";
import Event from "../../views/communication/events/store/reducer";
import GallaryList from "../../views/communication/reports/gallaryDetails/store/reducer";
import AttendenceSMS from "../../views/communication/academicsms/attendencesms/store/reducer";
import BwSMSUsage from "../../views/communication/reports/branchwiseSMSUsageReport/store/reducer";
import SmsUsageReport from "../../views/communication/reports/smsUsageReports/store/reducer";
import ResultSMS from "../../views/communication/academicsms/resultSms/store/reducer";
import SmsHistory from "../../views/communication/reports/smsHistory/store/reducer";
import SmsApiUdl from "../../views/communication/reports/apiBalAndUdlReport/store/reducer";
import SmsTrnsfrReducer from "../../views/communication/allocations/smsTransfer/store/reducer/index.js";
import ScheduleSmsReducer from "../../views/communication/academicsms/scheduleSms/store/reducer/index.js";
import SocialMediaCalendar from "../../views/communication/socialMedia/calendar/store/reducer";
// finance
import invoiceReducer from "@src/views/finance/invoice/store/reducer";
import ReprintReceipt from "../../views/finance/income/rePrintReceipt/store/reducer";
import InvoiceTerms from "../../views/finance/invoice/invTerms/store/reducer";
import InvoiceList from "../../views/finance/invoice/invoiceList/store/reducer";
//invoice Edit
import invoiceEditReducer from "@src/views/finance/invoice/invoiceEdit/store/reducer";
// finance seetings
import banks from "@src/views/finance/financeSettings/banks/store/reducer";
import approval from "@src/views/finance/financeSettings/approvalSettings/store/reducer";
import accounttype from "@src/views/finance/financeSettings/accountTypes/store/reducer";
import feecategories from "@src/views/finance/financeSettings/feeCategories/store/reducer";
import feeheads from "@src/views/finance/financeSettings/feeHeads/store/reducer";
import feeByClass from "@src/views/finance/financeSettings/feeByClass/store/reducer";
import feeByRoute from "@src/views/finance/financeSettings/feeByRoute/store/reducer";
import ReceiptSeries from "@src/views/finance/financeSettings/receiptSeries/store/reducer";
import FeeSynch from "@src/views/finance/financeSettings/feesSynchronization/store/reducer";
import IpPermitsReducer from "../../views/finance/financeSettings/ipPermits/store/reducer/index.js";
import UtilityReducer from "../../views/finance/bills/utility/store/reducer/index.js";
//import FeeByRoute from "../../views/finance/financeSettings/feeByRoute/list/store/reducer";

// finance transfers
import financetransfer from "@src/views/finance/financetransfers/financetransfer/store/reducer";
//income
import incomecollection from "@src/views/finance/income/incomeCollection/store/reducer";
import otherincome from "@src/views/finance/income/otherIncome/store/reducer";
import erpincome from "@src/views/finance/income/erpIncome/store/reducer";
import IndussPaymentReducer from "@src/views/finance/indussPay/store/reducer";
import IncomeReconcReducer from "../../views/finance/income/incomeReconciliation/store/reducer";
import SalarySettings from "@src/views/finance/payRoll/salarySettings/store/reducer";
import SalStReducer from "@src/views/finance/payRoll/salaryStatements/store/reducer";
import PayRollConditions from "../../views/finance/payRoll/payRollConditions/store/reducer";
// expenses
import expensesentry from "@src/views/finance/expenses/expensesentry/store/reducer";
import ExpReconcReducer from "../../views/finance/expenses/expReconciliation/store/reducer/index.js";
import employeeadvances from "@src/views/finance/expenses/employeeAdvances/store/reducer";
import ReprintVoucher from "../../views/finance/expenses/rePrintVoucher/store/reducer";
import VerfBillsReducer from "../../views/finance/expenses/billVerification/store/reducer/index.js";
import StuExpReducer from "../../views/finance/expenses/studentExpenseReport/store/reducer/index.js";
import SalaryPostingReducer from "../../views/finance/expenses/salaryPosting/store/reducer";
//Pocket Money
import DepWithReducer from "@src/views/finance/pocketMoney/depositWithdraw/store/reducer";
import PckMnyBtwDatesReducer from "../../views/finance/pocketMoney/pocketMoneyBtwDatesReport/store/reducer/index.js";
import ReprintPocketMoney from "../../views/finance/pocketMoney/reprintPocketMoney/store/reducer/index.js";
//analysis reports
import cashOrBankBook from "@src/views/finance/analysisReports/cashBankBook/store/reducer";
import viewByCustomerId from "../../views/finance/analysisReports/viewByCustId/store/reducer";
import EmpVendReport from "@src/views/finance/analysisReports/empVendReport/store/reducer";
import EmployeeVendReport from "../../views/finance/analysisReports/empVendLatestReport/store/reducer";
import FeeCard from "../../views/finance/analysisReports/feeCard/store/reducer";
import DailyCollection from "../../views/finance/analysisReports/dailyCollection/store/reducer";
import ConcReportReducer from "../../views/finance/analysisReports/concessionReport/store/reducer";
import FHExpReportReducer from "../../views/finance/analysisReports/finHeadsReport/store/reducer/index.js";
import ExpBrachPaidReducer from "../../views/dashboard/FinanceDashboard/ExpBranchPaidReport/store/reducer/index.js";
import MonthlyReportReducer from "../../views/finance/analysisReports/MonthlyReport/store/reducer";
import DayEndMessageReducer from "../../views/finance/analysisReports/DayEndMessage/store/reducer";
import AccountWiseAnalysis from "../../views/finance/analysisReports/accountWiseAnalysis/store/reducer";
//pending payments
import PendingPayments from "@src/views/finance/pendinganddeletedreports/pendingpayments/store/reducer";
import TermDueReport from "@src/views/finance/pendinganddeletedreports/termDueReport/store/reducer";
import feeCountReducer from "../../views/finance/pendinganddeletedreports/feeCallsReport/store/reducer/index.js";
import empAdvReportReducer from "../../views/finance/pendinganddeletedreports/empAdvancesReport/store/reducer/index.js";
import FHPendPayReducer from "../../views/finance/pendinganddeletedreports/feeHeadWisePendingPayments/store/reducer/index.js";
import FeeCallDtlsReducer from "../../views/finance/pendinganddeletedreports/feeCallDtlsReport/store/reducer/index.js";
import CatgPendBillsReducer from "../../views/finance/bills/catgWisePendBills/store/reducer/index.js";
import GroupBillsReducer from "../../views/finance/bills/catgWisePendBills/groupBills/store/reducer/index.js";
import GroupBillDtlRefReducer from "../../views/finance/bills/GroupBillsByBillDtlRef/store/reducer/index.js";
//Settings
import ChangeUsername from "../../views/settings/accountDetails/changeUsername/store/reducer";
import ChangePassword from "../../views/settings/accountDetails/changePassword/store/reducer";
import AuthorizationsReducer from "../../views/settings/Authorizations/store/reducer";

//Bills
import vendors from "@src/views/finance/bills/vendors/store/reducer";
import BillVendors from "../../views/finance/bills/billsEntry/list/store/reducer";
import RemDtBillsReducer from "../../views/finance/bills/remindBills/store/reducer/index.js";
import BetweenBillsReducer from "../../views/finance/bills/betweenDaysBills/store/reducer/index.js";
import tdsPendReducer from "../../views/finance/bills/tdsPendingBills/store/reducer/index.js";
//Budget
import Budget from "../../views/finance/budget/budgetAllotments/store/reducer";

//wallet Statement
import WalletStatementReducer from "../../views/finance/walletStatement/store/reducer/index.js";

// student
// admission / remove
import registrations from "@src/views/students/admissions/registrations/store/reducer";
import studentAdmRes from "@src/views/students/admissions/reservations/store/reducer";
//import stuDynamicEdit from "@src/views/students/DynamicEdit/store/reducer";
import stuDynamicEdit from "../../views/students/DynamicEdit/store/reducer";
import GenQRCode from "../../views/students/QRCodeGeneration/store/reducer";
import StudentsList from "../../views/students/ListOfStudents/store/reducer";
import TransferCertificate from "../../views/students/certificates/transferCertificate/store/reducer";
import AttPercentage from "../../views/students/certificates/attendanceCertificate/store/reducer/index.js"; 
import grades from "@src/views/academics/academicSettings/grades/store/reducer";
import studentUpdate from "@src/views/students/admissions/studentupdate/store/reducer";
import studentActivities from "@src/views/students/studentSettings/studentActivities/store/reducer";
import studentEnquiry from "@src/views/students/admissions/studentEnquiry/store/reducer";
import siblingMapping from "@src/views/students/admissions/siblingMapping/store/reducer";
import StudentRemove from "../../views/students/admissions/studentRemove/store/reducer";
import StuAdmitDropOutReport from "../../views/students/admitDropOutReport/store/reducer";
import StudyCertificate from "../../views/students/certificates/studyCertificates/list/store/reducer";
import StuLogDtlsReducer from "../../views/students/studentSettings/studentLogin/store/reducer/index.js";
import CasteReportReducer from "../../views/students/consolidatedCounts/store/reducer/index.js";
import HealthCardReducer from "../../views/students/studentSettings/healthRecard/store/reducer/index.js";
import AdmRegFormData from "../../views/students/admsRegForm/store/reducer/index.js"; 
//Period Allotments
import PeriodAllotments from "../../views/academics/lesson-planning/period-allotment/store/reducer";
import DateAllotments from "../../views/academics/lesson-planning/date-allocation/store/reducer";
// Hostel
import HstlBlockReducder from "../../views/hostel/hostelSettings/blocks/store/reducer";
import HstlRoomReducer from "../../views/hostel/hostelSettings/rooms/store/reducer";
import HstlRoomAllotReducer from "../../views/hostel/hostelSettings/roomAllocations/store/reducer";
import RoomAllocateReducer from "../../views/hostel/allocations/allocationReport/store/reducer";
import RoomVacateReducer from "../../views/hostel/vacateDetails/vacateReport/store/reducer";
import HosStuAttReducer from "../../views/hostel/hostelAtt/store/reducer/index.js";
import VisitorpassReducer from "../../views/hostel/vacateDetails/visitorPass/store/reducer";
// add route
import addroute from "@src/views/transport/addRoute/store/reducer";
import addBuses from "@src/views/transport/addBuses/store/reducer";
import RoutWiseStudents from "../../views/transport/RouteWiseStudents/store/reducer";
import VehiclesFuel from "../../views/transport/vehicleFuel/store/reducer";
import VehicleReading from "../../views/transport/vehicleReading/store/reducer";
import VehicleTyres from "../../views/transport/vehicleTyres/store/reducer";
import VehicleTyreTrfr from "../../views/transport/vehicleTyreTrfr/store/reducer";
import VehicleExpences from "../../views/transport/vehicleExpenseDtls/store/reducer"; 

import VehiclePapers from "../../views/transport/vehiclePapers/store/reducer";

import addVehicles from "@src/views/transport/addVehicles/store/reducer";
import RouteAllocation from "../../views/transport/routeAllocation/store/reducer";
import VehReadingEntryReducer from "../../views/transport/dailyReadingsEntry/store/reducer/index.js";
import VehFuelEntryReducer from "../../views/transport/fuelEntryPopup/store/reducer/index.js";

// Inventory
import InventoryCategory from "../../views/inventory/inventorySettings/inventoryCategories/store/reducer";
import SalePrice from "../../views/inventory/inventorySettings/salePrice/store/reducer";
import InventoryItems from "../../views/inventory/inventorySettings/inventoryItems/store/reducer";
import InventoryTransfer from "../../views/inventory/saleOrReturn/inventoryTransfer/store/reducer";
import InventorySale from "../../views/inventory/saleOrReturn/inventorySale/store/reducer";
import InventoryCombo from "../../views/inventory/inventorySettings/inventoryCombo/store/reducer";
import InventoryIndent from "../../views/inventory/inventorySettings/inventoryindent/store/reducer";
import InventoryIndentPend from "../../views/inventory/pendingDistributions/indentPendingList/store/reducer";
import PurchaseOrderReq from "../../views/inventory/inventorySettings/purchaseOrderRequest/store/reducer";
import PurchasePendList from "../../views/inventory/pendingDistributions/purchasePendingSale/store/reducer";
import DistPend from "../../views/inventory/pendingDistributions/distributionsPendingList/store/reducer";
import ReprintInvReceipt from "../../views/inventory/rePrint-Receipt/store/reducer";
//attendance
import workingDayes from "@src/views/attendance/attendanceSettings/InstituteTimeings/store/reducer";
import leaveTypes from "@src/views/attendance/leaveManagement/leaveTypes/store/reducer";
import Holidayes from "@src/views/attendance/attendanceSettings/holidaysList/store/reducer";
import extraLeave from "@src/views/attendance/leaveManagement/extraLeaves/store/reducer";
import employeeWork from "@src/views/attendance/attendanceSettings/employeeWorkHours/store/reducer";
import calenderReducer from "../../views/attendance/attendanceReports/MyAttendance/store/reducer";
import SmartCardsReducer from "../../views/attendance/attendanceSettings/smartCards/store/reducer";
import BulkExemptionReducer from "../../views/attendance/bulkExemption/store/reducer";
import EmpWorkHours from "@src/views/attendance/attendanceSettings/employeeWorkHours/store/reducer";
import AttSynch from "@src/views/attendance/attendanceSettings/StuAttSyncronization/store/reducer";
import StfMonthReportReducer from "../../views/attendance/attendanceReports/staffMonthWiseReport/store/reducer";
import StfLeaveReportReducer from "../../views/attendance/attendanceReports/leaveInformation/store/reducer";
import StuMonthReportReducer from "../../views/attendance/attendanceReports/stuMonthWiseAtt/store/reducer";
import StuMonthInOutReducer from "../../views/attendance/attendanceReports/stuAttReport/stuMonthReport/store/reducer/index.js";
import HourlyAttendance from "../../views/attendance/hourlyAttendance/store/reducer";

import myattendance from "@src/views/attendance/attendanceReports/MyAttendance/store/reducer";
import empReducer from "@src/views/attendance/attendanceExemption/store/reducer";
import leaveApplyData from "../../views/attendance/leaveManagement/leaveApply/store/reducer";
import StuAttendance from "@src/views/attendance/studentAttendance/store/reducer";
import PeriodWiseAttendance from "@src/views/attendance/PeriodWiseAttendance/store/reducer";
import EmpAttendance from "@src/views/attendance/employeeAttendance/store/reducer";
import StaffIOAttendance from "@src/views/attendance/attendanceReports/stfAttReport/store/reducer";
import StuIOAttendance from "@src/views/attendance/attendanceReports/stuAttReport/store/reducer";

//Employees
//Employess department add
import empdepadd from "@src/views/employees/settings/employeeDepartmentAdd/store/reducer";
import empadd from "@src/views/employees/Add/add/store/reducer";
import employeeUpdate from "@src/views/employees/Add/individualEmployeeDetails/store/reducer";
import EmployeeRemove from "../../views/employees/Add/empRemove/store/reducer";
import empDynamicEdit from "@src/views/employees/DynamicEdit/store/reducer";
import EmpLogDtlsReducer from "../../views/employees/empLoginDtls/store/reducer/index.js";
import EmployeeDesignations from "../../views/employees/settings/employeeDesignations/store/reducer";
import EmpList from "../../views/employees/employeeList/store/reducer";
import EmpOverview from "../../views/employees/overview/store/reducer";
import EmpDtlsList from "../../views/employees/empDtls/stores/reducer";
//Notifications
import notificationsReducer from "../../@core/layouts/components/navbar/Stepper/redux/reducer";
import coursesReducer from "@src/views/chess/components/store/reducer";
//import { desegnationReducer } from "../../@core/layouts/components/navbar/Stepper/redux/reducer"
import StuAnns from "../../views/crm/parent/store/reducer";
import QuestionEntry from "../../views/academics/onlineExams/questionEntry/store/reducer";
import ExamFixingReducer from "../../views/academics/onlineExams/examFixing/store/reducer";
import OnlineExams from "../../views/academics/onlineExams/onlineExams/store/reducer";
import WriteExam from "../../views/academics/onlineExams/writeExam/store/reducer";
import SelfAssmtQueReducer from "../../views/academics/onlineExams/selfAssmtQueCount/store/reducer/index.js";
import SelfAssmtRvsnReducer from "../../views/dashboard/StudentDashboard/selfAssmtRevision/store/reducer/index.js";

//Announcements
import Announcements from "../../views/crm/Announcement/store/reducer";
//ID Cards
import PrintSheetGeneration from "../../views/IdCards/generation-alteration/printSheetGeneration/store/reducer";
import PrintTray from "../../views/IdCards/generation-alteration/printtray/store/reducer";
import StudentImgUpload from "../../views/IdCards/ImageUpload/stuImgUpload/store/reducer";
import Captured from "../../views/IdCards/ImageUpload/capturedImages/stores/reducer.js";
import IdCardGeneration from "../../views/IdCards/generation-alteration/idCardGeneration/store/reducer";
import idTagReducer from "../../views/IdCards/idTagsUpload/store/reducer/index.js"; 
import IDTemplateAddReducer from "../../views/IdCards/templates/templateAdding/store/reducer/index.js";
import DamagedCardsReducer from "../../views/IdCards/idCardsDamaged/store/reducer/index.js";

const rootReducer = combineReducers({
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,

  // common reducers
  commonreducer,

  //Dashboard Reducer
  dashboardreducer,
  acaddashboardreducer,
  branchListreducer,
  reaReducer,
  studentDashboard,
  StuDashboard,
  crmDashboard,
  npDashboard,
  EnquiriesReports,
  SmsDashboard,
  InventoryDashboard,
  adbDashboard,
  AttDashboardReducer,
  VASDetailsReducer,

  //Customers
  IMSReducer,
  VehicleReminders,

  CompanyAdd,
  CustomerView,
  CompanyList,
  PriceCardReducer,
  CompLockReducer,
  CompProfileReducer,
  CreditReducer,

  // academic
  AnswerReducer,
  //WriteExam
  WriteExam,
  SelfAssmtQueReducer,
  SelfAssmtRvsnReducer,
  // results
  progressexams,
  resultentry,
  ResultViewByCls,
  TchrResAnalysisReducer,
  MarksVerfyReducer,
  // academic settings
  naipunya,
  Syllabus,
  classes,
  degrees,
  sections,
  ProgressCards,
  mediums,
  departments,
  academicyear,
  RegNoSeries,
  //Lesson Planning
  lessonPlanningDWSReducer,
  classTeacherMappinG,
  tentativeExams,
  SubjectSchedule,
  // subjects
  subjects,
  subjectmappings,
  subjectTopic,
  //QuestionEntry,
  QuestionEntry,
  ExamFixingReducer,
  OnlineExams,
  //TokenEntry
  TokenEntry,
  TokenErp,
  smsReducer,
  SmsTemplateTracker,
  MarketingSources,
  FeedBack,
  FeedbackCycle,
  FeedbackQues,
  ParentApp,
  GalleryEntry,
  WorkUpdate,
  StuPromoteDemote,
  StuExcelUpload,
  StuIncomeExcelUpload,
  IdExcelUploadReducer,
  TutorialReducer,
  TutorialLinkReducer,
  GetRefReducer,
  RecExpBillDelReducer,
  // exam cell
  exams,
  halltickets,
  // diary
  diaryadding,
  AllDiaries,
  PendDiaryReducer,
  // cbse/icse settings
  cbscpattern,
  cbscgroups,
  cbscSubjects,
  descriptive,
  CbsePattern,
  // finance
  invoiceReducer,
  invoiceEditReducer,
  ReprintReceipt,
  InvoiceTerms,
  InvoiceList,
  //Communication
  SmsAllocation,
  Event,
  GallaryList,
  UtilityList,
  AttendenceSMS,
  BwSMSUsage,
  SmsUsageReport,
  ResultSMS,
  SmsHistory,
  SmsApiUdl,
  SmsTrnsfrReducer,
  ScheduleSmsReducer,
  SocialMediaCalendar,
  // finance settings
  banks,
  approval,
  accounttype,
  feecategories,
  feeheads,
  feeByClass,
  feeByRoute,
  ReceiptSeries,
  FeeSynch,
  IpPermitsReducer,
  UtilityReducer,
  SalarySettings,
  SalStReducer,
  PayRollConditions,
  //FeeByRoute,
  // finance transfers
  financetransfer,
  // expenses
  expensesentry,
  ExpReconcReducer,
  ReprintVoucher,
  VerfBillsReducer,
  StuExpReducer,
  SalaryPostingReducer,
  //Pocket Money
  DepWithReducer,
  PckMnyBtwDatesReducer,
  ReprintPocketMoney,
  //pending payments
  PendingPayments,
  TermDueReport,
  feeCountReducer,
  empAdvReportReducer,
  FHPendPayReducer,
  FeeCallDtlsReducer,
  CatgPendBillsReducer,
  GroupBillsReducer,
  GroupBillDtlRefReducer,

  //analysis reports
  cashOrBankBook,
  viewByCustomerId,
  employeeadvances,
  EmpVendReport,
  EmployeeVendReport,
  FeeCard,
  DailyCollection,
  ConcReportReducer,
  FHExpReportReducer,
  ExpBrachPaidReducer,
  MonthlyReportReducer,
  DayEndMessageReducer,
  AccountWiseAnalysis,
  //income
  incomecollection,
  otherincome,
  erpincome,
  IndussPaymentReducer,
  IncomeReconcReducer,
  //Budget
  Budget,
  //Wallet Statement
  WalletStatementReducer,
  //Setting
  ChangeUsername,
  ChangePassword,
  AuthorizationsReducer,
  ModuelAccess,
  //bills
  vendors,
  BillVendors,
  RemDtBillsReducer,
  BetweenBillsReducer ,
  tdsPendReducer,

  //studentAttendance
  StuAttendance,
  PeriodWiseAttendance,
  EmpAttendance,
  StaffIOAttendance,
  StuIOAttendance,
  // work hours
  // EmpWorkHours,

  // students
  // admission / remove

  studentAdmRes,
  stuDynamicEdit,
  registrations,
  studentUpdate,
  GenQRCode,
  StudentsList,
  TransferCertificate,
  AttPercentage,
  grades,
  studentActivities,
  studentEnquiry,
  siblingMapping,
  StudentRemove,
  StuAdmitDropOutReport,
  StudyCertificate,
  StuLogDtlsReducer,
  CasteReportReducer,
  HealthCardReducer,
  AdmRegFormData,
  //period Allotments
  PeriodAllotments,
  DateAllotments,
  //Hostel
  HstlBlockReducder,
  HstlRoomReducer,
  HstlRoomAllotReducer,
  RoomAllocateReducer,
  RoomVacateReducer,
  HosStuAttReducer,
  VisitorpassReducer,
  SchOnlineClass,
  UpcOnlineClass,
  onlineClsAtt,
  TimeTableReducer,
  DayTimeTableReducer,
  TimeTableAdd,
  CoScholasticReducer,
  ElectiveSubjReducer,
  //SpeakWell
  Speakwell,
  swTopicsReducer,
  swMentorsReducer,
  swReviewReducer,
  swCmpltdReducer,
  //Naipunya
  NaipunyaStuDB,
  NaipunyaUndReviewReducer,
  //SubjectSchedule
  SubjectSchedule,
  // transport
  addroute,
  addBuses,
  RoutWiseStudents,
  addVehicles,
  RouteAllocation,
  VehReadingEntryReducer,
  VehFuelEntryReducer,
  VehicleReading,
  VehicleExpences,
  VehicleTyres,

  VehiclePapers,
  VehiclesFuel,
  VehicleTyreTrfr,
  // Inventory
  InventoryCategory,
  SalePrice,
  InventoryItems,
  InventoryTransfer,
  InventorySale,
  InventoryCombo,
  InventoryIndent,
  InventoryIndentPend,
  PurchaseOrderReq,
  PurchasePendList,
  DistPend,
  ReprintInvReceipt,
  //attendance
  workingDayes,
  leaveTypes,
  Holidayes,
  extraLeave,
  empReducer,
  notificationsReducer,
  coursesReducer,
  leaveApplyData,
  SmartCardsReducer,
  BulkExemptionReducer,
  //Employee
  empdepadd,
  empadd,
  EmployeeDesignations,
  employeeWork,
  EmpWorkHours,
  AttSynch,
  StfMonthReportReducer,
  StfLeaveReportReducer,
  StuMonthReportReducer,
  StuMonthInOutReducer,
  HourlyAttendance,
  employeeUpdate,
  empDynamicEdit,
  EmpLogDtlsReducer,
  EmployeeRemove,
  EmpList,
  EmpOverview,
  EmpDtlsList,
  myattendance,
  calenderReducer,
  StuAnns,
  Announcements,
  //ID Cards
  PrintSheetGeneration,
  PrintTray,
  StudentImgUpload,
  Captured,
  IdCardGeneration,
  idTagReducer,
  IDTemplateAddReducer,
  DamagedCardsReducer
});

export default rootReducer;
